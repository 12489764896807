import React, { FC, useEffect, useState } from 'react';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { ReactComponent as ProfileIcon } from '../../assets/img/profile-menu.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/info.svg';
import { ReactComponent as CrossIcon } from '../../assets/img/cross.svg';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { deleteCookie, emitEvent, passwordIsValid, setCookie, validatePassword, validateRequired } from '../../services/utils';
import { getUserInfo, handleHttpError } from '../../services/api';
import { createAssessment } from '../../services/assessment';
import Moment from 'moment';
import { getRate } from '../../services/rate';
import { RateData } from '../../types/rate';
import { useLocation } from 'react-router-dom';
import { ModalDlg } from '../ModalDlg';
import { PasswordInput } from '../PasswordInput';
import { changeAccountPassword } from '../../services/account';
import { raiseNotice } from '../Notices/Notices';

export const Header: FC = () => {
    Moment.locale('ru');

    const [showModalPrices, setShowModalPrices] = useState<boolean>(false);
    const [showModalPricesSelectButtons, setShowModalPricesSelectButtons] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [showChgPassDlg, setShowChgPassDlg] = useState(false);
    const [chgPassData, setChgPassData] = useState<any>({ password: '', new_password: '', confirm_password: '' });
    const [showChgPassValidationErrors, setShowChgPassValidationErrors] = useState(false);
    const [showSupportMsg, setShowSupportMsg] = useState(false);

    const { pathname } = useLocation();

    const handleModalPricesClose = () => {
        setShowModalPrices(false);
        return false;
    };

    const doLogout = () => {
        deleteCookie('auth');
        window.location.href = '/rdc-admin';
    };

    const tarifSelected = (rate_id: number) => {
        if (userInfo) {
            getRate(rate_id).then((rate:RateData) => {
                if (rate) {
                    createAssessment({
                        name: 'Оценка компании ' + userInfo.company_name,
                        account_num: '',
                        company_num: '',
                        start: Moment().format('YYYY-MM-DDThh:mm:ss'),
                        company_id: userInfo.company_id,
                        rate_id: rate_id,
                        is_paid: rate.price < 1e-3,
                        is_finished: false,
                        reports: [],
                        result: 0
                    }).then(res => {
                        setShowModalPrices(false);
                        emitEvent('assessment_created', {});
                    }).catch(handleHttpError);
                }
            }).catch(handleHttpError);
        }
    };

    useEffect(() => {
        getUserInfo().then(res => setUserInfo(res));

        window.addEventListener('create_assessment', function (e) {
            setShowModalPricesSelectButtons(true);
            setShowModalPrices(true);
        });
    }, []);

    const validateConfirmPassword = (value: any):string|undefined => {
        if ((value ?? '') == '') {
            return 'Обязательное поле';
        } else if (chgPassData.new_password != chgPassData.confirm_password)
            return '';
        return undefined;
    }

    const chgPassDlgSubmit = () => {
        changeAccountPassword(userInfo.login, chgPassData.password, chgPassData.new_password, chgPassData.confirm_password).then(r => {
            if (typeof r == 'boolean' && r) {
                setShowChgPassDlg(false);
                raiseNotice('info', 'Вы успешно сменили пароль для входа в личный кабинет.');
            }
        }).catch(handleHttpError);
    }

    return (
        <>
        <header className={styles.header}>
            <div className={styles.sitename}>БССГ</div>
            <ul className={styles.topmenu}>
                <li><a className={classNames({ [styles.active]: pathname.indexOf('/company') == -1 })} href="/assessments">Опросы</a></li>
                <li><a className={classNames({ [styles.active]: pathname.indexOf('/company') != -1 })} href="/company">Данные компании</a></li>
                <li><a className={classNames({ [styles.active]: false })} href="#" onClick={() => { setShowModalPricesSelectButtons(false); setShowModalPrices(true); }}>Тарифы и платные услуги</a></li>
            </ul>
            <div className={styles.sep}></div>
            <div className={styles.support}>
                <Button type='orangetext' className={styles.btn_support} label='Поддержка' onClick={() => setShowSupportMsg(true)} />
                {showSupportMsg && (<div className={styles.support_msg}>
                    <button className={styles.btn_close} onClick={() => setShowSupportMsg(false)}><CrossIcon /></button>
                    <p>По всем вопросам, связанным с работой платформы, вы можете писать в службу поддержки по адресу: <a href={`mailto:opros@bssg.ru?subject=${encodeURIComponent('Запрос к поддержке платформы оценки')}`}>opros@bssg.ru</a></p>
                </div>)}
            </div>
            <Button type='orange' label='Создать опрос' onClick={() => { setShowModalPricesSelectButtons(true); setShowModalPrices(true); }} />
            {userInfo && (
                <>
                    <Dropdown align='end'>
                        <Dropdown.Toggle><ProfileIcon /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item disabled>{userInfo.login}</Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowChgPassDlg(true)}>Сменить пароль</Dropdown.Item>
                            <Dropdown.Item onClick={doLogout}>Выход</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <ModalDlg show={showChgPassDlg} onClose={() => setShowChgPassDlg(false)} title='Сменить пароль' dialogClassName={styles.dlg_chg_pass} onSubmit={chgPassDlgSubmit}>
                        <PasswordInput label='Текущий пароль' isRequired showValidationError={showChgPassValidationErrors} validate={validateRequired} value={chgPassData.password} onChange={(e) => { setChgPassData((prev:any) => ({...prev, password: e.target.value })) }} />
                        <PasswordInput label='Новый пароль' isRequired showValidationError={showChgPassValidationErrors} validate={validatePassword} value={chgPassData.new_password} onChange={(e) => { setChgPassData((prev:any) => ({...prev, new_password: e.target.value })) }} />
                        {!passwordIsValid(chgPassData.new_password) && (<div className='psw_err'>Пароль должен состоять не менее, чем из восьми символов и включать заглавные и строчные латинские буквы, не менее одной цифры и специального символа</div>)}
                        <PasswordInput label='Повторите новый пароль' isRequired showValidationError={showChgPassValidationErrors} validate={validateConfirmPassword} value={chgPassData.confirm_password} onChange={(e) => { setChgPassData((prev:any) => ({...prev, confirm_password: e.target.value })) }} />
                        {chgPassData.new_password != chgPassData.confirm_password && (<div className={'psw_err'}>Пароли не совпадают</div>)}
                    </ModalDlg>
                </>
            )}
        </header>
        <Modal show={showModalPrices} backdrop={true} onHide={handleModalPricesClose} dialogClassName={styles.dlg_prices}>
            <Modal.Header closeButton><Modal.Title>Доступные тарифы и платные услуги</Modal.Title></Modal.Header>
            <Modal.Body>
                <div className={styles.prices_wrap}>
                    <div className={styles.prices_col}>
                        <div className={styles.title}>Базовый<br/>Бесплатно</div>
                        <div>
                            <div className={classNames(styles.type, styles.grey)}>Автоматическая оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>50 респондентов</li>
                                <li>экспресс-анкета (38 вопросов)</li>
                                <li>единая оценка без разделения на домены данных</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Отчёт об оценке</small>
                            <ul className={styles.checks}>
                                <li>соответствие уровню</li>
                                <li>численная оценка</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>100 респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>полная анкета (136 вопросов)</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>отчет с рекомендациями</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>проведение интервью</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' type='empty' onClick={() => tarifSelected(1)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={styles.prices_col}>
                        <div className={styles.title}>Экспресс<br/>25 000 ₽</div>
                        <div>
                            <div className={classNames(styles.type, styles.orange)}>Экспертная оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>100 респондентов</li>
                                <li>5 интервью</li>
                                <li>3 домена данных</li>
                                <li>экспресс-анкета (38 вопросов)</li>
                                <li>менеджер сопровождения</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Презентация с рекомендациями</small>
                            <ul className={styles.checks}>
                                <li>уровень и численная оценка</li>
                                <li className={styles.plus}>комментарии и пояснения</li>
                                <li className={styles.plus}>рекомендации по развитию</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>1000 респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>15 интервью</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>5 доменов данных</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>полная анкета (136 вопросов)</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' onClick={() => tarifSelected(2)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={styles.prices_col}>
                        <div className={styles.title}>Стандарт<br/>50 000 ₽</div>
                        <div>
                            <div className={classNames(styles.type, styles.orange)}>Экспертная оценка</div>
                        </div>
                        <div className={styles.section1}>
                            <label>Условия</label>
                            <ul className={styles.checks}>
                                <li>1000 респондентов</li>
                                <li>15 интервью</li>
                                <li>5 доменов данных</li>
                                <li>полная анкета (136 вопросов)</li>
                                <li>менеджер сопровождения</li>
                            </ul>
                        </div>
                        <div className={styles.section2}>
                            <label>Результат</label>
                            <small>Презентация с рекомендациями</small>
                            <ul className={styles.checks}>
                                <li>уровень, оценка, комментарии</li>
                                <li>рекомендации по развитию</li>
                                <li className={styles.plus}>дорожная карта</li>
                            </ul>
                        </div>
                        <div className={styles.section3}>
                            <label>Платные услуги</label>
                            <ul className={styles.arrows}>
                                <li><a className={styles.info} href=''><InfoIcon /></a>1000+ респондентов</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>доп. интервью</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>доп. домены данных</li>
                                <li><a className={styles.info} href=''><InfoIcon /></a>индивидуальная анкета (совмещение с внутренними опросами компании)</li>
                            </ul>
                        </div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <Button label='Выбрать тариф' onClick={() => tarifSelected(3)} />
                            </div>
                        </>
                        )}
                    </div>

                    <div className={classNames(styles.prices_col, styles.orange)}>
                        <div className={styles.title}>Расширенный</div>
                        <div className={classNames(styles.title, styles.white)}>Выберите этот вариант, если вам нужны особые условия</div>
                        {showModalPricesSelectButtons && (
                        <>
                            <div className={styles.sep}></div>
                            <div className={styles.bottom}>
                                <a href="mailto:info@bssg.ru?subject=Запрос на расширенную оценку" className={styles.link_send_request}>Отправить запрос</a>
                            </div>
                        </>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
};